import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PageOpening from "../components/pageOpening"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "003a19e9-4e78-5c62-ad59-a63cf59cd0b7" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          video

          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allSanityServices(sort: { fields: publishedAt, order: ASC }) {
        edges {
          node {
            title
            subtitle
            slug {
              current
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}
      <div className="sub_header">
        {data.allSanityServices.edges.map(function(service) {
          return (
            <Link to={`/${service.node.slug.current}/`}>
              {service.node.title}
            </Link>
          )
        })}
      </div>
      <section className="index services">
        {log(data.sanityPages.mainImage)}
        <PageOpening data={data} />
        <div className="service_wrapper">
          {data.allSanityServices.edges.map(function(service) {
            return (
              <Link to={`/${service.node.slug.current}/`}>
                <div className="single_service">
                  <h2>{service.node.title}</h2>
                  <p>{service.node.subtitle}</p>

                  <Link to={`/${service.node.slug.current}/`}>Læs mere</Link>
                </div>
              </Link>
            )
          })}
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default IndexPage
function log(log) {
  console.log(log)
}
